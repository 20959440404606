<template>
    <div v-if="dataSource" class="p-relative">

        <toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>
            <v-tab>
                {{ $t("Карточка") }}
            </v-tab>
        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item>
                <v-form class="wrapperForm" ref="form" lazy-validation>
                    <v-row dense>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Номенклатура-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Номенклатура") }}</div>

                                    <!--поле Наименование-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Наименование")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Header ? dataSource.Data.Object.Header : $t("Не_указано") }}
                                            </div>
                                            <v-textarea
                                                v-else
                                                :value="dataSource.Data.Object.Header"
                                                @input="update({ property: 'Data.Object.Header', value: $event })"
                                                rows="3"
                                                no-resize
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <!--поле Код-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Индекс")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Code ? dataSource.Data.Object.Code : $t("Не_указано") }}
                                            </div>
                                            <v-text-field
                                                v-else
                                                :value="dataSource.Data.Object.Code"
                                                @input="update({ property: 'Data.Object.Code', value: $event })"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="requiredRule"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--поле Структурное подразделение-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Структурное_подразделение") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Community ? dataSource.Data.Object.Community.Name : $t("Не_указано") }}
                                            </div>
                                            <treeselect
                                                v-else
                                                :options="communities"
                                                :load-options="loadCommunities"
                                                :value="dataSource.Data.Object.ParentId"
                                                @input="onCommunityChange"
                                                :multiple="false"
                                                :show-count="true"
                                                placeholder=""
                                                noResultsText="Нет_результатов"
                                                :clearable="false"
                                                :class="dataSource.Data.Object.ParentId == '00000000-0000-0000-0000-000000000000' ? 'chipAreaNoData' : ''"
                                            >
                                                <div slot="value-label" slot-scope="{ node }">{{ node.raw === null ? '' : node.raw.label }}</div>

                                                <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                                    <v-icon
                                                        v-if="node.isBranch"
                                                        x-small
                                                    >
                                                        fas fa-folder
                                                    </v-icon>
                                                    <v-icon
                                                        v-else
                                                        x-small
                                                    >
                                                        fas fa-layer-group
                                                    </v-icon>
                                                    {{ node.label }}
                                                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                                                </label>

                                            </treeselect>
                                        </v-col>
                                    </v-row>

                                    <!--поле Тип документа-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Тип_документа")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.DocumentType ? $t(docTypes[dataSource.Data.Object.DocumentType]) : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="allowedDocTypes"
                                                item-value="id"
                                                :item-text="item => $refLocale(item, $i18n.locale)"
                                                :value="dataSource.Data.Object.DocumentType"
                                                @input="update({ property: 'Data.Object.DocumentType', value: $event })"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!--поле Место регистрации-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Место_регистрации")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.RegistrationPlace ? dataSource.Data.Object.RegistrationPlace.Value : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                return-object
                                                :items="reference(1019)"
                                                item-value="id"
                                                :item-text="item => $refLocale(item, $i18n.locale)"
                                                :value="dataSource.Data.Object.RegistrationPlace"
                                                @input="update({ property: 'Data.Object.RegistrationPlace', value: $event })"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!--поле Срок хранения-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Срок_хранения")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.LifePeriod ? dataSource.Data.Object.LifePeriod.Value : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                return-object
                                                :items="reference(1020)"
                                                item-value="id"
                                                :item-text="item => $refLocale(item, $i18n.locale)"
                                                :value="dataSource.Data.Object.LifePeriod"
                                                @input="update({ property: 'Data.Object.LifePeriod', value: $event })"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="6" class="section-wrapper">
                            <!--Блок Параметры дела-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Параметры_дела") }}</div>

                                    <!--поле Дата начала-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_начала")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.BeginDate ? $moment(dataSource.Data.Object.BeginDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                            </div>
                                            <v-menu
                                                v-else
                                                v-model="beginDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="beginDate | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="beginDate"
                                                    :max="endDate"
                                                    @input="beginDateMenu = false"
                                                    color="teal"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>

                                    <!--поле Дата завершения-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_завершения")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.EndDate ? $moment(dataSource.Data.Object.EndDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                            </div>
                                            <v-menu
                                                v-else
                                                v-model="endDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="endDate | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="endDate"
                                                    :min="beginDate"
                                                    @input="endDateMenu = false"
                                                    color="teal"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>

                                    <!--поле Журнал-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{ $t("Журнал") }}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Counter ? `${dataSource.Data.Object.Counter.Name} ${dataSource.Data.Object.Counter.Year}` : $t("Не_указано") }}
                                            </div>
                                            <v-select
                                                v-else
                                                :items="allowedJournals"
                                                item-value="id"
                                                :item-text="item => $refLocale(item, $i18n.locale)"
                                                :value="dataSource.Data.Object.CounterId"
                                                @input="update({ property: 'Data.Object.CounterId', value: $event })"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    

                                    <!--поле Дело закрыто-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дело_закрыто")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.IsClosed ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                :input-value="dataSource.Data.Object.IsClosed"
                                                @change="update({ property: 'Data.Object.IsClosed', value: $event })"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                    <!--поле Переходящее дело-->
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Переходящее_дело")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="8">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.IsTransit ? $t("Да") : $t("Нет") }}
                                            </div>
                                            <v-switch
                                                v-else
                                                :input-value="dataSource.Data.Object.IsTransit"
                                                @change="update({ property: 'Data.Object.IsTransit', value: $event })"
                                                inset
                                                hide-details
                                                class="cust-switch"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!--Обертка для секции-->
                        <v-col cols="12" sm="12" md="12" class="section-wrapper">
                            <!--Блок Примечание-->
                            <v-card flat>
                                <v-card-text>
                                    <div class="form-box-title">{{ $t("Примечание") }}</div>
                                    
                                    <!--поле Наименование-->
                                    <v-row class="full-width-row" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Примечание")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="10">
                                            <div v-if="isViewMode" class="onlyReadData">
                                                {{ dataSource.Data.Object.Comment ? dataSource.Data.Object.Comment : $t("Не_указано") }}
                                            </div>
                                            <v-textarea
                                                v-else
                                                :value="dataSource.Data.Object.Comment"
                                                @input="update({ property: 'Data.Object.Comment', value: $event })"
                                                rows="3"
                                                no-resize
                                                hide-details
                                                required
                                                outlined
                                                dense
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-form>
            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import i18n from '@/i18n'
import _ from 'lodash'

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect';

export default {
    name: "Nomenclature",
    components: {
       Toolbar,
       Treeselect
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data: () => ({
        tab: null,
        beginDateMenu: false,
        endDateMenu: false,
        journalsSource: [],
        communities: null,
    }),
    computed: {
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
        ...mapGetters('references', { docTypes: 'GetDocumentTypes', reference: 'GetReference'}),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            integerNotNullRule: 'getIntegerNotNullRule',
            defaultGuidRule: 'getDefaultGuidRule'
        }),
        isViewMode() {
            return this.dataSource?.Data.FormId === "0103021";
        },
        allowedDocTypes() {
            let $docTypes = this.docTypes;
            return Object.keys($docTypes).reduce(function (types, docType) {
                let intDocType = parseInt(docType);
                if ([1, 3, 4, 5].includes(intDocType)) {
                    types.push({ id: intDocType, Value: i18n.t($docTypes[intDocType]) });
                }
                return types;
            }, []);
        },
        allowedJournals() {
            let journalObj = this.journalsSource.find(x => x.type === this.dataSource.Data.Object.DocumentType);
            
            if (journalObj)
                return journalObj.journals;
            else
                return [];
        },
        beginDate: {
            get: function() {
                if (this.dataSource.Data.Object.BeginDate)
                    return this.$moment(this.dataSource.Data.Object.BeginDate).format('YYYY-MM-DD');
                
                return this.dataSource.Data.Object.BeginDate;
            },
            set: function(value) {
                this.updateSource({ property: 'Data.Object.BeginDate', value: `/Date(${Date.parse(value)})/` });
            }
        },
        endDate: {
            get: function() {
                if (this.dataSource.Data.Object.EndDate)
                    return this.$moment(this.dataSource.Data.Object.EndDate).format('YYYY-MM-DD');
                
                return this.dataSource.Data.Object.EndDate;
            },
            set: function(value) {
                this.updateSource({ property: 'Data.Object.EndDate', value: `/Date(${Date.parse(value)})/` });
            }
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form.validate();
                    if(validate_result){
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        async loadCommunities({ action }) {
            if (action === LOAD_ROOT_OPTIONS) {

                let response = await httpAPI({
                    url: `/api/references/departments`,
                    method: 'GET'
                });

                if (response) {
                    this.communities = [];
                    let departments = response.data?.payload?.data?.map(x => ({ id: x.id, parent: x.parent, label: x.text }) ) ?? [];
                    
                    departments.forEach(record => {
                        let founded = departments.find(x => x.id === record.parent);

                        if (founded) {
                            if (typeof founded.children === 'undefined') {
                                founded.children = [];
                            }

                            founded.children.push(record);
                        }
                    });

                    this.communities = departments.filter(x => x.parent === '#');
                }
                else
                    this.communities = [];
            }
        },
        onCommunityChange(value) {
            this.updateSource({ property: 'Data.Object.ParentId', value });
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
    },
    async created() {
        this.setOverlayVisible({ visible: true, text: `Загрузка_журналов_регистрации...` });

        [1, 3, 4, 5].forEach( async docType => {
            let response = await httpAPI({
                url: `/api/references/counters/${docType}`,
                method: 'GET'
            });

            if (response)
                this.journalsSource.push({ type: docType, journals: response.data.payload.data?.map( x => ({ id: x[0], Value: `${x[2]} ${x[1]}` })) ?? [] });
            else
                this.journalsSource.push({ type: docType, journals: [] });
        });

        this.setOverlayVisible({ visible: false });

        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs.form.validate();
        })();
    },
    updated(){
        if(this.dataSource !== null){
            this.$refs?.form?.validate();
        }  
    }
}
</script>